<template>
  <div>
    <div class="text-detail">
      <div class="content mt-8">
        <div
          class="text-detail__title"
          v-if="PageContent.name"
          v-html="PageContent.name"
        ></div>
      </div>
      <div class="content">
        <div class="text-detail__row">
          <div class="text-detail__body">
            <video
              controls
              src="https://storage.yandexcloud.net/az-most.ru-storage/media/uploads/materials/video/2023/06/05/%D0%98%D1%81%D0%BA%D1%83%D1%81%D1%81%D1%82%D0%B2%D0%BE_%D0%BF%D1%83%D0%B1%D0%BB%D0%B8%D1%87%D0%BD%D0%BE%D0%B3%D0%BE_%D0%B2%D1%8B%D1%81%D1%82%D1%83%D0%BF%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F-min.mp4"
              poster="https://storage.yandexcloud.net/az-most.ru-storage/media/uploads/materials/announce/2023/06/05/%D0%98%D1%81%D0%BA%D1%83%D1%81%D1%81%D1%82%D0%B2%D0%BE_%D0%BF%D1%83%D0%B1%D0%BB%D0%B8%D1%87%D0%BD%D0%BE%D0%B3%D0%BE_%D0%B2%D1%8B%D1%81%D1%82%D1%83%D0%BF%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F.jpeg"
              ref="videoElement"
            ></video>
            <div
              v-show="PageContent.description"
              class="text-detail__subtitle"
              v-html="PageContent.description"
              ref="description"
            ></div>
          </div>
          <div class="sticky">
            <RightAside
              @medicationsClick="medicationsClick"
              @calendarClick="calendarClick"
              @nosologyClick="nosologyClick"
              v-if="
                asideItems && asideItems.length && PageContent.content.length
              "
              :items="asideItems"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AsideBlock from "@/components/pageComponents/AsideBlock.vue";
import "@/assets/scss/detail_content.scss";
import RightAside from "@/components/pageComponents/RightAside.vue";

export default {
  metaInfo() {
    return {
      title:
        "Спикер-тренинг «Сафнело® – новое слово в терапии СКВ». Часть 2. Искусство презентации: как преподносить данные так, чтобы они запомнились? Мастерство публичного выступления врача",
    };
  },
  components: {
    AsideBlock,
    RightAside,
  },
  name: "safneloPartTwo",
  data: (vm) => ({
    items: [],
    index: null,
  }),
  computed: {
    loadedRefs() {
      return this.$refs;
    },
    asideItems() {
      if (this.PageContent && Object.keys(this.PageContent).length) {
        return [
          {
            title: "Препараты АЗ",
            image_desktop: require("@/assets/img/cardio-img/aside-1_small.jpg"),
            image: require("@/assets/img/cardio-img/aside-1.jpg"),
            theme: "white",
            event: "medicationsClick",
            to: {
              name: "Medications",
            },
          },
          {
            title: "Календарь мероприятий",
            image: require("@/assets/img/cardio-img/aside-2.jpg"),
            image_desktop: require("@/assets/img/cardio-img/aside-2_small.jpg"),
            event: "calendarClick",
            to: {
              name: "Events",
            },
          },
          {
            title: "Терапевтические <br>области",
            image: require("@/assets/img/cardio-img/aside-4.jpg"),
            image_desktop: require("@/assets/img/cardio-img/aside-4_small.jpg"),
            event: "nosologyClick",
            to: { name: "Nosology" },
          },
        ];
      } else {
        return [];
      }
    },
    PageContent() {
      return {
        id: 11,
        content: [
          {
            active: true,
          },
        ],
        description: "Ведущий — бизнес-тренер Михаил Прокофьев",
        title:
          "Спикер-тренинг «Сафнело® – новое слово в терапии СКВ». Часть 2. Искусство презентации: как преподносить данные так, чтобы они запомнились? Мастерство публичного выступления врача",
        name: "Спикер-тренинг «Сафнело® – новое слово в терапии СКВ». Часть 2. Искусство презентации: как преподносить данные так, чтобы они запомнились? Мастерство публичного выступления врача",
        color: "#830051",
        hash: "d530f65bddd82072a6c39735e3a005c8",
      };
    },
  },
  methods: {
    medicationsClick(item) {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "videomaterial page medications click", {
          "videomaterial page medications click": {
            ...this.$root.ymFields,
          },
        });
      }
      this.$router.push(item.to).catch(() => {});
    },
    calendarClick(item) {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "videomaterial page events click", {
          "videomaterial page events click": {
            ...this.$root.ymFields,
          },
        });
      }
      this.$router.push(item.to).catch(() => {});
    },
    nosologyClick(item) {
      if (typeof ym !== "undefined") {
        ym(
          91468266,
          "reachGoal",
          "videomaterial page therapeutic areas click",
          {
            "videomaterial page therapeutic areas click": {
              ...this.$root.ymFields,
            },
          }
        );
      }
      this.$router.push(item.to).catch(() => {});
    },
  },
  //   async mounted() {
  //     const vm = this;
  //     setTimeout(() => {
  //       if (
  //         this.loadedRefs[`description`] &&
  //         this.loadedRefs[`description`].querySelector(".timecodes")
  //       ) {
  //         this.loadedRefs[`description`]
  //           .querySelectorAll(".timecodes__time")
  //           .forEach(async function (item) {
  //             item.addEventListener("click", function () {
  //               let time = this.innerText.split(":");
  //               let hours = parseInt(time[0].padStart(2, 0));
  //               let minutes = parseInt(time[1].padStart(2, 0));
  //               let seconds = parseInt(time[2].padStart(2, 0));
  //               let result = hours * 60 * 60 + minutes * 60 + seconds;
  //               vm.loadedRefs[`videoElement`].currentTime = result;
  //               vm.loadedRefs[`videoElement`].scrollIntoView({
  //                 behavior: "smooth",
  //               });
  //             });
  //           });
  //       }
  //       if (this.loadedRefs[`videoElement`]) {
  //         const video = this.loadedRefs[`videoElement`];
  //         if (this.$route.query.t) {
  //           video.currentTime = this.$route.query.t;
  //         }
  //         video.addEventListener("play", function () {
  //           if (this.currentTime === 0) {
  //             if (typeof ym !== "undefined") {
  //               ym(91468266, "reachGoal", "video start", {
  //                 "video start": {
  //                   "video name": vm.VideoDetail.name,
  //                   "video duration": Math.round(this.duration),
  //                   ...vm.$root.ymFields,
  //                 },
  //               });
  //             }
  //           } else {
  //             if (typeof ym !== "undefined") {
  //               ym(91468266, "reachGoal", "video resume", {
  //                 "video resume": {
  //                   "video name": vm.VideoDetail.name,
  //                   "video duration": Math.round(this.duration),
  //                   ...vm.$root.ymFields,
  //                 },
  //               });
  //             }
  //           }
  //           vm.cyclicVideo(Math.round(this.duration));
  //         });
  //         video.addEventListener("pause", function () {
  //           if (this.currentTime !== this.duration) {
  //             if (typeof ym !== "undefined") {
  //               ym(91468266, "reachGoal", "video pause", {
  //                 "video pause": {
  //                   "video name": vm.VideoDetail.name,
  //                   "video duration": Math.round(this.duration),
  //                   ...vm.$root.ymFields,
  //                 },
  //               });
  //             }
  //           }
  //           clearTimeout(vm.timer);
  //           vm.timer = null;
  //         });
  //         video.addEventListener("ended", function () {
  //           if (typeof ym !== "undefined") {
  //             ym(91468266, "reachGoal", "video end", {
  //               "video end": {
  //                 "video name": vm.VideoDetail.name,
  //                 "video duration": Math.round(this.duration),
  //                 ...vm.$root.ymFields,
  //               },
  //             });
  //           }
  //         });
  //         video.addEventListener("timeupdate", function () {
  //           let percent = Math.ceil((this.currentTime / this.duration) * 100);
  //           if (this.percent !== percent && percent !== 0 && percent % 5 === 0) {
  //             if (typeof ym !== "undefined") {
  //               ym(91468266, "reachGoal", "video percentage watched", {
  //                 "video percentage watched": {
  //                   "video percentage watched": percent,
  //                   ...vm.$root.ymFields,
  //                 },
  //               });
  //             }
  //             this.percent = percent;
  //           }
  //         });
  //       }
  //     }, 300);
  //   },
  watch: {},
};
</script>

<style lang="scss" scoped>
.text-detail {
  padding-top: 64px;
  @media screen and (max-width: 1220px) {
    padding-top: 40px;
  }

  &__row {
    margin-top: 32px;
    display: grid;
    grid-template-columns: 1fr 280px;
    column-gap: 136px;
    align-items: flex-start;

    @media screen and (max-width: 1220px) {
      grid-template-columns: 100%;
      row-gap: 40px;
    }
  }

  &__title {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;
    color: #1f1f1f;

    @media screen and (max-width: 767px) {
      font-size: 24px;
      line-height: 32px;
    }
  }
  &__subtitle {
    margin-bottom: 24px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 29px;
    color: #1f1f1f;
    @media screen and (max-width: 767px) {
      font-size: 21px;
      line-height: 26px;
    }
  }

  &__buttons {
    margin-top: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media screen and (max-width: 1220px) {
      margin-bottom: 80px;
    }

    @media screen and (max-width: 767px) {
      margin-bottom: 0;
      flex-direction: column;
      align-items: stretch;
    }
  }
  &__load {
    width: 110px;
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 12px;
    }
  }

  &__up {
    margin-left: auto;
    width: 213px;

    @media screen and (max-width: 767px) {
      width: 100%;
      margin-left: 0;
    }
  }
}

.sticky {
  @media screen and (min-width: 1221px) {
    position: sticky;
    top: 115px;
  }
}

.material {
  &__tags-wrap {
    margin-bottom: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media screen and (max-width: 1220px) {
      flex-direction: column;
    }
  }
  &__tags {
    margin-right: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    @media screen and (max-width: 1220px) {
      margin-bottom: 16px;
    }
    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
    }
  }

  &__tag {
    padding-left: 10px;
    position: relative;
    margin-right: 8px;
    // margin-bottom: 8px;
    margin-top: 4px;
    margin-bottom: 4px;
    display: inline-flex;
    align-items: center;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #3c4242;
    white-space: nowrap;
    // max-width: calc(50% - 16px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: break-spaces;

    @media screen and (max-width: 767px) {
      margin-bottom: 8px;
    }

    &::before {
      position: absolute;
      top: 10px;
      left: 0;
      transform: translateY(-50%);
      width: 2px;
      height: 2px;
      background-color: #3c4242;
      border-radius: 50%;
      display: block;
      content: "";
    }

    &:last-child {
      margin-right: 0;
    }

    &_new {
      position: absolute;
      top: 8px;
      left: 8px;
      z-index: 4;
      display: inline-block;
      padding: 4px 8px;
      background: rgba(255, 255, 255, 0.8);
      border: 1px solid #d8dada;
      border-radius: 4px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 13px;
      color: #1f1f1f;
    }
  }
}

.favorite {
  @media screen and (max-width: 767px) {
    width: 100%;
    margin-top: 16px;
  }
}
</style>
